import { EventCategoryResource } from './event-category-resource';

export class EventCategory {

  static readonly thumbnailSrc: { [categoryId: number]: string } = {
    1: 'assets/dummies/msuevent/partys.svg',
    3: 'assets/dummies/msuevent/und_sonst.svg',
    4: 'assets/dummies/msuevent/ausstellungen.svg',
    7: 'assets/dummies/msuevent/feste.svg',
    9: 'assets/dummies/msuevent/fuehrungen.svg',
    13: 'assets/dummies/msuevent/maerkte_und_basare.svg',
    14: 'assets/dummies/msuevent/literatur.svg',
    16: 'assets/dummies/msuevent/vortraege.svg',
    26: 'assets/dummies/msuevent/buehne.svg',
    27: 'assets/dummies/msuevent/konzerte.svg',
    28: 'assets/dummies/msuevent/politik.svg',
    53: 'assets/dummies/msuevent/genusswelt.svg',
    54: 'assets/dummies/msuevent/film.svg',
    55: 'assets/dummies/msuevent/glaube.svg',
    56: 'assets/dummies/msuevent/info_und_beratung.svg',
    57: 'assets/dummies/msuevent/natur_und_umwelt.svg',
  };

  readonly id: number;
  readonly title: string;
  readonly thumbnailSrc: null | string;

  constructor(
    resource: EventCategoryResource['data']
  ) {
    this.id = resource.id;
    this.title = resource.title;
    this.thumbnailSrc = EventCategory.thumbnailSrc[this.id] ?? null;
  }

}
