import * as _dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import 'dayjs/esm/locale/de';

_dayjs.locale('de');
_dayjs.extend(utc);
_dayjs.extend(timezone);

export const dayjs = _dayjs;
export type Dayjs = _dayjs.Dayjs;
