import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PredefinedColors } from '@ionic/core';

export interface Badge {
  text: string;
  color: PredefinedColors;
}

@Component({
  selector: 'mn-footer-menu-item',
  templateUrl: './footer-menu-item.component.html',
  styleUrls: ['./footer-menu-item.component.scss'],
})
export class FooterMenuItemComponent {

  @Input() icon: string;
  @Input() label: string;
  @Input() badge: null|Badge = null;

  @Output() mnClick = new EventEmitter<void>();

  click() {
    this.mnClick.emit();
  }

}
