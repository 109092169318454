import { Component, Input } from '@angular/core';
import { ItemComponent } from '../../item/item.component';
import { Image } from '../../../../entities/image/image';
import { Router } from '@angular/router';

@Component({
  selector: 'mn-home-item-card',
  templateUrl: './home-item-card.component.html',
  styleUrls: ['./home-item-card.component.scss'],
})
export class HomeItemCardComponent extends ItemComponent {

  @Input() path = '';

  constructor(private router: Router) {
    super();
  }

  get image(): Image {
    if (this.isMeLocalObject(this.item)) {
      return this.item.thumbnail;
    }

    return this.item.image;
  }

  get city(): string|null {
    if (this.isMovieDate(this.item)) {
      return null;
    }

    return this.item.location.city;
  }

  get routerLink() {
    if (this.isMeLocalObject(this.item)) {
      const routerLink = this.item.routerLink();
      routerLink[0] = this.path;
      return routerLink;
    }

    return this.item.routerLink();
  }

  async openDetailView() {
    await this.router.navigate(this.routerLink);
  }

}
