import { MeLocalCategoryResource } from './me-local-category-resource';

export class MeLocalCategory {

  static readonly thumbnailSrc: { [categoryId: number]: string } = {
    9728: 'assets/dummies/me-local/erlebnisse.svg',
    9711: 'assets/dummies/me-local/gastronomie.svg',
    9693: 'assets/dummies/me-local/kultur.svg',
    9732: 'assets/dummies/me-local/shopping.svg',
    9707: 'assets/dummies/me-local/unterkunft.svg',
  };

  readonly id: number;
  readonly title: string;
  readonly thumbnailSrc: null | string;

  constructor(
    resource: MeLocalCategoryResource['data'],
    categoryRootID?: number
  ) {
    this.id = resource.id;
    this.title = resource.title;
    this.thumbnailSrc = categoryRootID
      ? MeLocalCategory.thumbnailSrc[categoryRootID] ?? null
      : null;
  }

}
