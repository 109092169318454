import { Component, ContentChild, Input, TemplateRef, TrackByFunction } from '@angular/core';
import { expand } from '../expander/expander.component';

export interface Entry {
  model: unknown;
  key: number | string;
  routerLink?: (string | number)[];
  replaceUrl?: boolean;
}

@Component({
  selector: 'mn-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  animations: [expand],
})
export class ExpandableListComponent {

  @Input()
  subject = 'anzeigen';

  @Input()
  entries: Entry[] = [];

  @ContentChild(TemplateRef)
  template: TemplateRef<unknown>;

  isExpanded = false;

  trackByEntry: TrackByFunction<Entry> = (_, entry) => entry.key;

}
