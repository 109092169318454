import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HandleUnrecoverableStateService {

  constructor(
    private updates: SwUpdate,
    private toastCtrl: ToastController,
    private logger: LoggerService,
  ) {}

  /**
   * @see https://angular.io/guide/service-worker-communications#handling-an-unrecoverable-state
   */
  invoke() {
    if (!environment.production) { return; }
    this.updates.unrecoverable.subscribe(async (event) => {
      this.logger.error(`An error occurred that we cannot recover from:\n${event.reason}`);

      const toast = await this.toastCtrl.create({
        message: 'Ein schwerwiegender Fehler ist aufgetreten. Bitte laden Sie Seite neu.',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Neu laden'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => document.location.reload());
    });
  }

}
