import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AsyncErrorHandler } from './async-error-handler';
import { HttpErrorResponse } from '@angular/common/http';
import { LikeLimitExceeded } from '../like-limit-exceeded';

@Injectable({
  providedIn: 'root'
})
export class ToastErrorHandler extends AsyncErrorHandler {

  constructor(private toast: ToastController) {
    super();
  }

  async handleError(error: unknown) {
    const message = this.message(error);

    if (message) {
      return await this.presentToast(message);
    }
  }

  protected async presentToast(message: string): Promise<void> {
    const duration = Math.min(Math.max(message.length * 50, 2000), 7000);
    const toast = await this.toast.create({ message, duration });
    await toast.present();
  }

  private message(error: unknown): null|string {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0: return 'Fehler beim Laden von Daten. Bitte prüfe Deine Internetverbindung und versuche es erneut.';
        case 404: return 'Die angefragte Resource ist nicht mehr verfügbar.';
        default: return 'Unbekannter Fehler beim Laden von Daten. Bitte versuche es später erneut.';
      }
    }

    if (error instanceof LikeLimitExceeded) {
      return 'Es können maximal 20 Einträge gemerkt werden.';
    }

    return null;
  }

}
