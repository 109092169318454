import {Component, Input, OnInit} from '@angular/core';
import {Location} from '../../../entities/location';

@Component({
  selector: 'mn-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  @Input() location: Location;

  constructor() { }

  ngOnInit() {}

}
