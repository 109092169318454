import { ErrorHandler, Injectable } from '@angular/core';
import { AsyncErrorHandler } from './async-error-handler';
import { ToastErrorHandler } from './toast-error-handler';
import { LogErrorHandler } from './log-error-handler';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {

  private handlers = Array<AsyncErrorHandler>();

  constructor(
    private logHandler: LogErrorHandler,
    private toastHandler: ToastErrorHandler,
  ) {
    this.handlers.push(
      ...Array
        .from(arguments)
        .filter((arg) => arg instanceof AsyncErrorHandler)
    );
  }

  handleError(error: any): void {
    // Unwrap possible zone.js Promise rejection errors
    error = error?.rejection || error;

    Promise
      .all(this.handlers.map((handler) => handler.handleError(error)))
      .finally();
  }

}
