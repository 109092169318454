import { AsyncErrorHandler } from './async-error-handler';
import { Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class LogErrorHandler extends AsyncErrorHandler {
  constructor(private logger: LoggerService) {
    super();
  }

  async handleError(error: unknown) {
    this.logger.error(error);
  }
}
