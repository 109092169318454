import {Component, Input, OnInit} from '@angular/core';
import { Location } from '../../../entities/location';
import { Contact } from '../../../entities/contact';

@Component({
  selector: 'mn-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {

  @Input() contact: Contact;

  constructor() { }

  ngOnInit() {}

}
