import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { LikeService } from './like.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { finalize, map, pairwise, skip, switchMap, tap } from 'rxjs/operators';
import { Like, Type as LikeType } from '../entities/like';
import { SearchParameters as BaseSearchParameters } from '../entities/search-parameters';
import { ResourceCollection } from '../entities/resource-collection';
import { withoutUndefined } from '../support/helpers';
import { Location } from '@angular/common';

export interface ItemWithLike<Item> {
  item: Item;
  like?: Like;
}

@Injectable()
export abstract class ListItemService<Item,
  SearchParameters extends BaseSearchParameters = BaseSearchParameters> implements OnDestroy {

  readonly searchParameters$: BehaviorSubject<SearchParameters>;
  readonly isLoading$ = new BehaviorSubject(false);
  readonly items$ = new BehaviorSubject<Item[]>([]);
  readonly total$ = new BehaviorSubject<number>(0);

  /**
   * The items with their corresponding likes
   *
   * If `this.likeType` is `null` or `this.id()` returns `null`, `ItemWithLike.like` is `undefined`.
   */
  readonly itemsWithLikes$: Observable<ItemWithLike<Item>[]> = combineLatest([
    this.items$,
    this.likeService.likes$,
  ]).pipe(
    switchMap(async ([items]) => {
      const itemIDs = items.map(this.id).filter((id): id is string => id !== null);
      const likes = this.likeType
        ? await this.likeService.likes(this.likeType, itemIDs)
        : [];

      return items.map((item) => {
        const itemID = this.id(item);
        const like = likes.find((_like) => _like.id === itemID);
        return { item, like };
      });
    }),
  );

  private subscriptions = Array<Subscription>();

  /**
   * List Item Service Constructor
   *
   * Without the `@Inject('')` decorators a production build would fail with the following error:
   * No suitable injection token for parameter 'apiURL' of class 'ListItemService'.
   *
   * @see https://github.com/angular/angular/issues/37769#issuecomment-934351037
   */
  protected constructor(
    @Inject('') protected readonly apiURL: string,
    @Inject('') initialSearchParameters: SearchParameters,
    @Inject('') private readonly likeType: null | LikeType = null,
    protected http: HttpClient,
    protected logger: LoggerService,
    protected likeService: LikeService,
    protected location: Location,
  ) {
    this.searchParameters$ = new BehaviorSubject(initialSearchParameters);

    this.subscriptions.push(
      this.isLoading$.pipe(skip(1)).subscribe((value) => {
        this.logger.debug(`${this.constructor.name} loading state changed to:`, value);
      }),
      this.total$.pipe(skip(1)).subscribe((value) => {
        this.logger.debug(`${this.constructor.name} total changed to:`, value);
      }),
      this.items$.pipe(skip(1)).subscribe((value) => {
        this.logger.debug(`${this.constructor.name} items changed to:`, value);
        if (value.length === 0) {
          this.total$.next(0);
        }
      }),
      this.searchParameters$.pipe(
        pairwise(),
        tap(([oldValue, newValue]) => {
          this.logger.debug(`${this.constructor.name} search parameters changed to:`, newValue);
          this.adjustBrowserURL(oldValue, newValue);
          this.load().subscribe();
        }),
      ).subscribe(),
    );
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length) {
      this.subscriptions.pop()?.unsubscribe();
    }
  }

  load(): Observable<Item[]> {
    const searchParams = this.searchParameters$.value;
    const httpParams = this.httpParams(searchParams);
    const isNewSearchRequest = searchParams.page === 1 && this.items$.value.length > 0;

    if (this.isLoading$.value) {
      return of(this.items$.value);
    }

    this.isLoading$.next(true);

    if (isNewSearchRequest) {
      this.items$.next([]);
    }

    return this.http.get<ResourceCollection<{ data: unknown }>>(
      this.apiURL,
      { params: httpParams },
    )
      .pipe(
        tap((resources) => this.total$.next(resources.meta.total)),
        map((resources) => this.items(resources, searchParams)),
        tap((items) => this.items$.next(this.items$.value.concat(...items))),
        finalize(() => this.isLoading$.next(false)),
      );
  }

  /**
   * Returns items observable matching the given `searchParameters`
   *
   * In contrast to e. g. `load()` this method has __no side effects__.
   */
  getItems(searchParameters: SearchParameters): Observable<Item[]> {
    return this.http.get<ResourceCollection<{ data: unknown }>>(
      this.apiURL,
      { params: this.httpParams(searchParameters) },
    )
      .pipe(
        map((resources) => this.items(resources, searchParameters)),
      );
  }

  /**
   * @todo: It should not be required to add BaseSearchParameters to the partial here because SearchParameters
   *        already extends it … However, TypeScript does not seem to understand it. Maybe in a future version…
   */
  mergeSearchParameters(overrides: Partial<BaseSearchParameters | SearchParameters>) {
    this.searchParameters$.next({
      ...this.searchParameters$.value,
      ...overrides
    });
  }

  resetFilters() {
  }

  get numberOfActiveFilters$(): Observable<number> {
    return of(0);
  }

  protected adjustBrowserURL(oldSearchParams: SearchParameters, newSearchParams: SearchParameters) {
    const httpParams = (_searchParams: SearchParameters): HttpParams => (new HttpParams())
      .appendAll({ ...withoutUndefined(_searchParams) })
      .delete('page')
      .delete('perPage');

    const oldHttpParams = httpParams(oldSearchParams).toString();
    const newHttpParams = httpParams(newSearchParams).toString();

    const paginationDidChange = (oldSearchParams.page !== newSearchParams.page)
      || (oldSearchParams.perPage !== newSearchParams.perPage);
    const httpParamsDidChange = oldHttpParams !== newHttpParams;

    if (paginationDidChange && !httpParamsDidChange) {
      return;
    }

    const absoluteUrl = this.location.path().split('?')[0];
    this.location.go(absoluteUrl, newHttpParams);
  }

  protected id(item: Item): null | string {
    return null;
  };

  private httpParams(searchParams: SearchParameters): HttpParams {
    return (new HttpParams()).appendAll({ ...withoutUndefined(searchParams) });
  }

  protected abstract items(
    resources: ResourceCollection<{ data: unknown }>,
    searchParameters: SearchParameters
  ): Item[];

}
