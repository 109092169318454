import { MovieGenreResource } from './movie-genre-resource';

export class MovieGenre {
  readonly id: string;
  readonly title: string;

  constructor(
    resource: MovieGenreResource['data']
  ) {
    this.id = resource.id;
    this.title = resource.title;
  }

}
