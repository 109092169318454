import {Component, Input, OnInit} from '@angular/core';
import { MovieDate } from '../../../entities/me-kino/date/movie-date';
import { LikeService } from '../../../services/like.service';
import { Like } from '../../../entities/like';
import { Router } from '@angular/router';

@Component({
  selector: 'mn-movie-list-item',
  templateUrl: './movie-list-item.component.html',
  styleUrls: ['./movie-list-item.component.scss'],
})
export class MovieListItemComponent implements OnInit {

  @Input() movieDate: MovieDate;
  @Input() like?: Like;

  constructor(private likeService: LikeService, private router: Router) {
  }

  ngOnInit() {
  }

  async openDetailView(movieDate: MovieDate) {
    await this.router.navigate(movieDate.routerLink());
  }

  async toggleLike(movieDate: MovieDate) {
    await this.likeService.toggle(movieDate);
  }
}
