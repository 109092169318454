import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromptUpdateService {

  constructor(
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
  ) {}

  /**
   * @see https://angular.io/guide/service-worker-communications#forcing-update-activation
   */
  invoke() {
    if (!environment.production) { return; }
    this.swUpdate.available.subscribe(async () => {
      const toast = await this.toastCtrl.create({
        message: 'Neue Version verfügbar!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Neu laden'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => document.location.reload());
    });
  }

}
