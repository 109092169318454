import { Image } from '../../image/image';
import { EventCategory } from '../category/event-category';
import { EventDateResource } from './event-date-resource';
import { EventFeature } from '../event-feature';
import { ImageResource } from '../../image/image-resource';
import { dayjs } from '../../../support/dayjs';
import { Router } from '@angular/router';
import { Routable } from '../../routable';

export class EventDate implements Routable {

  id: string;
  eventId: number;
  title: string;
  categories: EventCategory[];
  features: EventFeature[];
  location: {
    city: null | string;
  };
  image: Image;
  ticketshopUrl: string | null;
  date: Date;

  constructor(
    resource: Omit<EventDateResource['data'], 'date' | 'image' | 'location'>
      & {
      date: string | Date;
      image: null | Image | ImageResource;
      location: EventDate['location'];
    }
  ) {
    this.id = resource.id;
    this.eventId = resource.eventId;
    this.title = resource.title;
    this.categories = resource.categories.map(category => new EventCategory(category));
    this.features = resource.features.map(feature => new EventFeature(feature));
    this.location = resource.location;
    this.image = resource.image || {
      src: this.categories.find(category => category.thumbnailSrc)?.thumbnailSrc ?? 'assets/dummies/neutral.svg',
      author: null,
    };
    this.ticketshopUrl = resource.ticketshopUrl;
    this.date = new Date(resource.date);
  }

  routerLink(router: null | Router = null) {
    return [
      '/event',
      this.eventId,
      dayjs(this.date).format('YYYY-MM-DD'),
    ];
  }

}
