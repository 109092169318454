import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogUpdateService {

  constructor(private updates: SwUpdate, private logger: LoggerService) {}

  /**
   * @see https://angular.io/guide/service-worker-communications#available-and-activated-updates
   */
  invoke() {
    if (!environment.production) { return; }
    this.updates.available.subscribe(event => {
      this.logger.debug('current version is', event.current);
      this.logger.debug('available version is', event.available);
    });
    this.updates.activated.subscribe(event => {
      this.logger.debug('old version was', event.previous);
      this.logger.debug('new version is', event.current);
    });
  }

}
