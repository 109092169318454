import { LabelComponent } from './label/label.component';
import { EventListItemComponent } from './event-list-item/event-list-item.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MessageComponent } from './message/message.component';
import { FooterMenuComponent } from './footer/footer-menu/footer-menu.component';
import { FooterMenuItemComponent } from './footer/footer-menu-item/footer-menu-item.component';
import { ExpanderComponent } from './expander/expander.component';
import { ListItemComponent } from './list-item/list-item.component';
import { MeLocalObjectListItemComponent } from './me-local-object-list-item/me-local-object-list-item.component';
import { LocationComponent } from './location/location.component';
import { CtaButtonsComponent } from './cta-buttons/cta-buttons.component';
import { DescriptionComponent } from './description/description.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { HeroComponent } from './hero/hero.component';
import { HomeSliderComponent } from './home/home-slider/home-slider.component';
import { HomeItemCardComponent } from './home/home-item-card/home-item-card.component';
import { HomeMoreCardComponent } from './home/home-more-card/home-more-card.component';
import { MovieListItemComponent } from './movie-list-item/movie-list-item.component';
import { CinemaMoviesComponent } from './cinema-movies/cinema-movies.component';
import { ExpandableListComponent } from './expandable-list/expandable-list.component';

export const COMPONENTS = [
  LabelComponent,
  EventListItemComponent,
  SpinnerComponent,
  MessageComponent,
  FooterMenuComponent,
  FooterMenuItemComponent,
  ExpanderComponent,
  ListItemComponent,
  MeLocalObjectListItemComponent,
  MovieListItemComponent,
  LocationComponent,
  CtaButtonsComponent,
  DescriptionComponent,
  ContactInfoComponent,
  HeroComponent,
  HomeSliderComponent,
  HomeItemCardComponent,
  HomeMoreCardComponent,
  CinemaMoviesComponent,
  ExpandableListComponent,
];
