import {IonContent} from '@ionic/angular';

export const scrollTo = async (cssSelector: string, content: IonContent) => {
  const target = document.querySelector(cssSelector);
  if (target instanceof HTMLElement) {
    await content.scrollToPoint(null, target.offsetTop, 500);
  }
};

/**
 * Returns copy of a given `_object` where all undefined fields got removed recursively
 *
 * Warning: Child objects of the given `_object` are copied by reference.
 */
export const withoutUndefined = <T extends unknown>(_object: Record<string, T>) => {
  const copy = { ..._object };
  const clean = (obj: Record<string, T>) => {
    for (const key of Object.keys(obj)) {
      if (obj[key] === undefined) {
        delete obj[key];
      } else if (obj[key] === Object(obj[key])) {
        clean(obj[key] as Record<string, T>);
      }
    }
    return obj;
  };
  return clean(copy) as Record<string, Exclude<T, undefined>>;
};
