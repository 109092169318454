import { Directive, Input } from '@angular/core';
import { EventDate } from '../../../entities/msuevent/date/event-date';
import { MeLocalObject } from '../../../entities/me-local/object/me-local-object';
import { MovieDate } from '../../../entities/me-kino/date/movie-date';
import { Like } from '../../../entities/like';
import { Model } from '../../../services/like.service';

@Directive()
export abstract class ItemComponent {

  @Input() item: Model;
  @Input() like?: Like;

  isEventDate(item): item is EventDate {
    return item instanceof EventDate;
  }
  isMovieDate(item): item is MovieDate {
    return item instanceof MovieDate;
  }

  isMeLocalObject(item): item is MeLocalObject {
    return item instanceof MeLocalObject;
  }

}
