import { Component, OnDestroy, OnInit } from '@angular/core';
import { LikeService } from './services/like.service';
import { LogUpdateService } from './services/log-update.service';
import { CheckForUpdateService } from './services/check-for-update.service';
import { PromptUpdateService } from './services/prompt-update.service';
import { HandleUnrecoverableStateService } from './services/handle-unrecoverable-state.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { DocumentLocationService } from './services/document-location.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private routerEventsSubscription?: Subscription;
  private defaultTitle: string;

  constructor(
    private likeService: LikeService,
    private logUpdateService: LogUpdateService,
    private checkForUpdateService: CheckForUpdateService,
    private promptUpdateService: PromptUpdateService,
    private handleUnrecoverableStateService: HandleUnrecoverableStateService,
    private location: Location,
    private documentLocationService: DocumentLocationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.manageUpdates();
    this.removeExpiredLikes().then();
    this.defaultTitle = this.titleService.getTitle();
    this.handlePageViews();
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  private manageUpdates() {
    if (!('serviceWorker' in navigator)) { return; }
    this.logUpdateService.invoke();
    this.checkForUpdateService.invoke();
    this.promptUpdateService.invoke();
    this.handleUnrecoverableStateService.invoke();
  }

  private async removeExpiredLikes() {
    await this.likeService.purge();
  }

  private handlePageViews() {
    this.routerEventsSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.setBrowserTitle()),
      )
      .subscribe();
  }

  private setBrowserTitle() {
    let title = this.defaultTitle;
    let route = this.activatedRoute;
    let didChangePageTitle = false;
    while(route.firstChild) {
      route = route.firstChild;
      title = route.snapshot.data.pageTitle || title;
      didChangePageTitle = route.snapshot.data.didChangePageTitle || false;
    }
    if (!didChangePageTitle) {
      this.titleService.setTitle(title);
    }
  }
}
