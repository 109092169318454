import { Component, Input } from '@angular/core';

@Component({
  selector: 'mn-home-more-card',
  templateUrl: './home-more-card.component.html',
  styleUrls: ['./home-more-card.component.scss'],
})
export class HomeMoreCardComponent {

  @Input() routerLink: any[];

}
