// noinspection PointlessBooleanExpressionJS

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {}

  /**
   * @see https://angular.io/guide/service-worker-communications#checking-for-updates
   */
  invoke() {
    if (!environment.production) { return; }
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyMinute$ = interval(60_000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    everyMinuteOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }

}
