import { Directive, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { fromEvent, Observable, ReplaySubject } from 'rxjs';
import { filter, takeUntil, takeWhile, tap, timeout } from 'rxjs/operators';

@Directive({
  selector: '[mnSearchSelect]',
})
export class SelectSearchDirective implements OnDestroy, OnChanges {

  @Input() mnSearchSelectPlaceholder = 'Suchen';

  /**
   * Emits one initial event and then once for each document.body subtree modification
   *
   * The main purpose of this directive is the manipulating of an <ion-select>'s alert.
   * Ionic adds alerts directly to the <ion-app> element. => ElementRef is out of scope.
   * Ionic does not provide meaningful alert events on the <ion-select> component.
   * This observable is used to overcome the resulting timing challenges.
   */
  private get body$(): Observable<void> {
    return new Observable(observer => {
      observer.next();
      const mutationObserver = new MutationObserver(() => {
        observer.next();
      });
      mutationObserver.observe(document.body, { childList: true, subtree: true });
      return () => observer.unsubscribe();
    });
  }

  private get alert(): null | HTMLIonAlertElement {
    // noinspection CssInvalidHtmlTagReference
    const alertElement = document.querySelector<HTMLIonAlertElement>('ion-alert');
    if (alertElement) {
      return alertElement;
    }
    return null;
  }

  private get alertMessage(): null | HTMLElement {
    return this.alert?.querySelector('.alert-message') || null;
  }

  private get alertOptionLabels(): HTMLElement[] {
    return Array.from(this.alert?.querySelectorAll('.alert-checkbox-label') || []);
  }

  private get isEmptySearchQuery(): boolean {
    return !Boolean(this.searchbar.value?.trim());
  }

  private readonly searchbar: HTMLIonSearchbarElement;

  /**
   * Completes on destroy
   */
  private teardown$ = new ReplaySubject(1);

  constructor() {
    this.searchbar = this.createSearchbar();
    this.handleSearchbarChanges();
  }

  @HostListener('click')
  private initializeAlert() {
    this.body$
      .pipe(
        timeout(10_000), // stop listening after 10s just in case something goes wrong …
        takeUntil(this.teardown$),
        takeWhile(() => !this.alertMessage, true),
        filter(() => this.alertMessage !== null),
        tap(() => {
          this.insertSearchbar();
          this.updateAlert();
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mnSearchSelectPlaceholder) {
      this.searchbar.placeholder = changes.mnSearchSelectPlaceholder.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.teardown$.next();
    this.teardown$.complete();

    this.searchbar.parentElement?.removeChild(this.searchbar);
  }

  private createSearchbar(): HTMLIonSearchbarElement {
    const searchbar = document.createElement('ion-searchbar');
    searchbar.placeholder = this.mnSearchSelectPlaceholder;
    searchbar.inputmode = 'search';
    searchbar.enterkeyhint = 'search';
    return searchbar;
  }

  private handleSearchbarChanges() {
    fromEvent(this.searchbar, 'ionChange')
      .pipe(
        takeUntil(this.teardown$),
        tap(() => this.updateAlert()),
      )
      .subscribe();
  }

  private insertSearchbar() {
    this.alertMessage?.insertAdjacentElement('afterend', this.searchbar);
  }

  private updateAlert() {
    for (const optionLabel of this.alertOptionLabels) {
      const checkbox = this.checkbox(optionLabel);
      if (!checkbox) { return; }

      if (this.isEmptySearchQuery || this.isMatchingSearchQuery(optionLabel)) {
        checkbox.style.display = '';
      } else {
        checkbox.style.display = 'none';
      }
    }
  }

  private checkbox(optionLabel: HTMLElement): null | HTMLElement {
    return optionLabel.closest<HTMLElement>('[role="checkbox"]');
  }

  private isMatchingSearchQuery(optionLabel: HTMLElement): boolean {
    return optionLabel.textContent
      ?.toLowerCase()
      .includes(this.searchbar.value?.toLowerCase() ?? '')
      ?? false;
  }

}
