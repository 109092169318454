import { Component, Input, OnInit } from '@angular/core';
import { EventDate } from '../../../entities/msuevent/date/event-date';
import { LikeService } from '../../../services/like.service';
import { Like } from '../../../entities/like';
import { dayjs } from '../../../support/dayjs';
import { Router } from '@angular/router';

@Component({
  selector: 'mn-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['../list-item/list-item.component.scss'],
})
export class EventListItemComponent implements OnInit {

  @Input() eventDate: EventDate;
  @Input() like?: Like;

  constructor(private likeService: LikeService, private router: Router) {
  }

  ngOnInit() {
  }

  async openDetailView(eventDate: EventDate) {
    await this.router.navigate(eventDate.routerLink());
  }

  async toggleLike(eventDate: EventDate) {
    await this.likeService.toggle(eventDate);
  }
}
