import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mn-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {

  @Input() spinner = 'bubbles';

  constructor() { }

  ngOnInit() {}

}
