import { Component, Input, TrackByFunction } from '@angular/core';
import { AnyHomeSliderDataService } from '../../../../services/home-slider-data.service';
import { Model } from '../../../../services/like.service';

@Component({
  selector: 'mn-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent {

  @Input() dataSource: AnyHomeSliderDataService;

  trackByItem: TrackByFunction<Model> = (_, item) => item.id;

}
