import { Component, Input } from '@angular/core';
import { MeLocalObject } from '../../../entities/me-local/object/me-local-object';
import { Like } from '../../../entities/like';
import { LikeService } from '../../../services/like.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mn-me-local-object-list-item',
  templateUrl: './me-local-object-list-item.component.html',
  styleUrls: ['../list-item/list-item.component.scss'],
})
export class MeLocalObjectListItemComponent {

  @Input() meLocalObject: MeLocalObject;
  @Input() like?: Like;

  constructor(
    private likeService: LikeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  async toggleLike(meLocalObject: MeLocalObject) {
    await this.likeService.toggle(meLocalObject);
  }

  async openDetailView(meLocalObject: MeLocalObject) {
    await this.router.navigate(
      meLocalObject.routerLink(this.router),
      { relativeTo: this.activatedRoute }
    );
  }

}
