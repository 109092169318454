import { Component, OnDestroy } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular/';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mn-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent implements OnDestroy {

  isKeyboardActive = false;

  actionSheetButtons = [
    {
      text: 'Merkliste',
      icon: 'heart',
      handler: () => {
        if (this.router.url.includes('likes')) { return; }
        // This is a KISS solution; it may require you to setup redirects on the router level.
        this.router.navigate(['./likes'], { relativeTo: this.route });
      }
    },
    {
      text: 'Schließen',
      icon: 'close',
      role: 'cancel',
    }
  ];

  private subscriptions = Array<Subscription>();

  constructor(
    private actionSheetController: ActionSheetController,
    private router: Router,
    private route: ActivatedRoute,
    platform: Platform,
  ) {
    this.subscriptions.push(
      platform.keyboardDidShow.subscribe(() => this.isKeyboardActive = true),
      platform.keyboardDidHide.subscribe(() => this.isKeyboardActive = false),
    );
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length) {
      this.subscriptions.pop()?.unsubscribe();
    }
  }

  async showFooterMenu() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'mn-action-sheet',
      buttons: this.actionSheetButtons
    });

    await actionSheet.present();
  }

}
