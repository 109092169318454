import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as log from 'loglevel';

import '@main-netz/destroy-links';

window.log = log;

if (environment.production) {
  enableProdMode();
  log.setDefaultLevel('error');
} else {
  log.setDefaultLevel('trace');
}

if ('Android' in window && Android && 'enablePullToRefresh' in Android && Android.enablePullToRefresh) {
  Android.enablePullToRefresh(false);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
