import { NullableTimePipe } from './nullable-time/nullable-time.pipe';
import { SeparatedPipe } from './separated/separated.pipe';
import { DatesPipe } from './dates/dates.pipe';
import { LocalDatePipe } from './local-date/local-date.pipe';

export const PIPES = [
  NullableTimePipe,
  SeparatedPipe,
  LocalDatePipe,
  DatesPipe
];
