import { Component, Input } from '@angular/core';

interface Button {
  label: string;
  icon: string;
  action: null | string | (() => void);
}

@Component({
  selector: 'mn-cta-buttons',
  templateUrl: './cta-buttons.component.html',
  styleUrls: ['./cta-buttons.component.scss'],
})
export class CtaButtonsComponent {

  get actionableButtons(): Button[] {
    return this.buttons.filter((button) => button.action);
  }

  @Input() buttons: Button[] = [];

  constructor() { }

  isString(value): value is string {
    return typeof value === 'string';
  }

  isCallable(value): value is () => unknown {
    return typeof value === 'function';
  }

}
