import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DocumentLocationService {

  get location(): Location {
    return this.document.location;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

}
