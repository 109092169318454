import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separated'
})
export class SeparatedPipe implements PipeTransform {

  /**
   * Returns joined array `value` using ', ' as a default separator;
   * supports a custom separator as first argument;
   * supports a path in dot notation to access nested object values as second argument;
   * filters out undefined / null values and empty strings.
   *
   * Custom separator example: {{ categories | separated:" • " }}
   * Path example: {{ items | separated:", ":"categories.title" }}
   */
  transform(value: Array<unknown>, ...args: string[]): string {
    let values = [...value];
    const [separator, path] = args;

    if (path) {
      values = values.map(
        val => path
          .split('.')
          .reduce<unknown>(
            (newValue, key) => newValue && typeof newValue === 'object' ? newValue[key] : undefined, val
          )
      );
    }

    return values
      .filter(v => v !== null && v !== undefined && v !== '')
      .join(separator ?? ', ');
  }

}
