import { Injectable } from '@angular/core';
import * as log from 'loglevel';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  get debug() {
    return log.debug.bind(log);
  }

  get error() {
    return log.error.bind(log);
  }

  get log() {
    return log.log.bind(log);
  }

  get info() {
    return log.info.bind(log);
  }

  get warn() {
    return log.warn.bind(log);
  }

  get trace() {
    return log.trace.bind(log);
  }

}
