/* eslint-disable no-underscore-dangle,@typescript-eslint/member-ordering */

import { Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { dayjs } from '../../../support/dayjs';
import { Cinema } from '../../../entities/me-kino/cinema/cinema';
import { MovieDateService } from '../../../services/movie-date.service';
import { Observable, of } from 'rxjs';
import { MovieDate } from '../../../entities/me-kino/date/movie-date';
import { ItemWithLike } from '../../../services/list-item.service';
import { delay, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mn-cinema-movies',
  templateUrl: './cinema-movies.component.html',
  styleUrls: ['./cinema-movies.component.scss'],
  providers: [{ provide: MovieDateService }]
})
export class CinemaMoviesComponent implements OnInit {

  @Input() cinema: Cinema;

  dates: Date[];

  isLoading$: Observable<boolean>;
  total$: Observable<number>;
  movieDates$: Observable<ItemWithLike<MovieDate>[]>;
  minHeight$: Observable<'100vh' | '0'>;

  trackByItemWithLike: TrackByFunction<ItemWithLike<MovieDate>> = (_, item) => item.item.id;

  get selectedDate(): Date {
    return this._selectedDate;
  }

  private _selectedDate = new Date();

  constructor(
    private movieDateService: MovieDateService,
  ) {
    this.dates = this.makeDates();

    this.isLoading$ = movieDateService.isLoading$.asObservable();
    this.total$ = movieDateService.total$.asObservable();
    this.movieDates$ = movieDateService.itemsWithLikes$;

    this.minHeight$ = this.isLoading$.pipe(
      switchMap(
        isLoading => isLoading
          ? of(isLoading)
          : of(isLoading).pipe(delay(100))
      ),
      map(isLoading => isLoading ? '100vh' : '0'),
    );
  }

  ngOnInit() {
    this.load();
  }

  isSelectedDate(date: Date): boolean {
    return dayjs(date).isSame(this.selectedDate);
  }

  selectDate(date: Date) {
    this._selectedDate = date;
    this.load();
  }

  private makeDates(): Date[] {
    const now = new Date();
    return (new Array(7))
      .fill(now)
      .map((date, index) => dayjs(date).add(index, 'day').toDate());
  }

  private load() {
    const startDate = dayjs(this.selectedDate).format('YYYY-MM-DD');
    const endDate = startDate;
    this.movieDateService.mergeSearchParameters({
      startDate,
      endDate,
      page: 1,
      perPage: 50,
      cinemas: `${this.cinema.id}`,
    });
  }

}
