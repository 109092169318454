import { MovieDateResource} from './movie-date-resource';
import { dayjs } from '../../../support/dayjs';
import { Router } from '@angular/router';
import { Routable } from '../../routable';
import { Image } from '../../image/image';
import { ImageResource } from '../../image/image-resource';
import { MovieGenre } from '../genre/movie-genre';

export class MovieDate implements Routable {

  id: string;
  movieId: number;
  title: string;
  showtimes: Date[];
  fsk: null | number;
  ov: boolean;
  genres: MovieGenre[];
  image: Image;
  date: Date;

  constructor(
    resource: Omit<MovieDateResource['data'], 'showtimes' | 'date' | 'image' >
      & {
      showtimes: (string | Date)[];
      date: string | Date;
      image: null | Image | ImageResource;
    }
  ) {
    this.id = resource.id;
    this.movieId = resource.movieId;
    this.title = resource.title;
    this.showtimes = resource.showtimes.map(showtime => new Date(showtime));
    this.fsk = resource.fsk;
    this.ov = resource.ov;
    this.genres = resource.genres.map(data => new MovieGenre(data));
    this.image = resource.image || {
      src: '/assets/dummies/me-kino/default_poster.svg',
      author: null
    };
    this.date = new Date(resource.date);
  }

  routerLink(router: null | Router = null) {
    const idTuple = [this.movieId, dayjs(this.date).format('YYYY-MM-DD')];

    if (!router) {
      return ['/me-kino/movie', ...idTuple];
    }

    const path = router.url.includes('likes') ? '..' : '.';
    return [path, ...idTuple];
  }

}
