import { Injectable } from '@angular/core';
import { EventDate } from '../entities/msuevent/date/event-date';
import { DatabaseService } from './database.service';
import { ID, Like, LikeKey, Type } from '../entities/like';
import { LoggerService } from './logger.service';
import { BehaviorSubject } from 'rxjs';
import { LikeLimitExceeded } from '../errors/like-limit-exceeded';
import { dayjs } from '../support/dayjs';
import { MeLocalObject } from '../entities/me-local/object/me-local-object';
import { MovieDate } from '../entities/me-kino/date/movie-date';

/**
 * The supported model types
 */
export type Model = EventDate | MeLocalObject | MovieDate;

@Injectable({
  providedIn: 'root'
})
export class LikeService {

  readonly likes$ = new BehaviorSubject<void>(undefined);

  constructor(
    private db: DatabaseService,
    private logger: LoggerService,
  ) {
  }

  /**
   * Returns like by a given `model`; falls back to `null`.
   */
  async like(model: null | Model): Promise<null | Like> {
    if (!model) {
      return null;
    }
    const { type, id } = this.parse(model);
    const key: LikeKey = [type, id];
    return (await this.db.likes.get(key)) || null;
  }

  /**
   * Returns all likes optionally filtered by type or type and IDs
   */
  async likes(type?: Type, ids?: ID[]): Promise<Like[]> {
    if (type && ids) {
      return this.db.likes
        .where('[type+id]')
        .anyOf(ids.map((id) => [type, id]))
        .toArray();
    }

    if (type) {
      return this.db.likes
        .where('type')
        .equals(type)
        .toArray();
    }

    return this.db.likes.toArray();
  }

  /**
   * Toggles a like for the given `model`
   */
  async toggle(model: null | Model): Promise<null | Like> {
    if (!model) {
      return null;
    }

    const like = this.parse(model);
    const { type, id } = like;
    const key: LikeKey = [type, id];
    const existingLike = await this.like(model);

    if (existingLike) {
      await this.db.likes.delete(key);
      this.likes$.next();
      this.logger.debug('LikeService removed one like:', existingLike);
      return null;
    }

    if ((await this.likes(like.type)).length >= 20) {
      throw new LikeLimitExceeded();
    }

    await this.db.likes.add(like, key);
    this.likes$.next();
    this.logger.debug('LikeService added a new like:', like);
    return like;
  }

  /**
   * Deletes expired likes
   */
  async purge(): Promise<number> {
    const expiredLikes = this.db.likes.where('expiresAt').below(new Date());
    this.logger.debug('LikeService purged expired likes:', await expiredLikes.toArray());
    const count = await expiredLikes.delete();
    this.likes$.next();
    return count;
  }

  protected parse(model: Model): Like {
    if (model instanceof EventDate) {
      return {
        type: 'msu-event',
        id: model.id,
        expiresAt: dayjs(model.date.toISOString())
          .utc()
          .add(2, 'days')
          .startOf('day')
          .toDate(),
      };
    }

    if (model instanceof MovieDate) {
      return {
        type: 'me-kino',
        id: `${model.id}`,
        expiresAt: dayjs(model.date.toISOString())
          .utc()
          .add(7, 'days')
          .startOf('day')
          .toDate(),
      };
    }


    return {
      type: 'me-local',
      id: `${model.id}`,
      expiresAt: null,
    };
  }

}
