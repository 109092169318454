import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceCollection } from '../entities/resource-collection';
import { environment } from '../../environments/environment';
import { ListItemService } from './list-item.service';
import { LoggerService } from './logger.service';
import { LikeService } from './like.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { withoutUndefined } from '../support/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MovieDate } from '../entities/me-kino/date/movie-date';
import { MovieDateSearchParameters } from '../entities/me-kino/date/movie-date-search-parameters';
import { MovieDateResource } from '../entities/me-kino/date/movie-date-resource';

@Injectable({
  providedIn: 'root'
})
export class MovieDateService extends ListItemService<MovieDate, MovieDateSearchParameters> {

  private static defaultSearchParameters: MovieDateSearchParameters = { page: 1, perPage: 20 };

  constructor(
    http: HttpClient,
    logger: LoggerService,
    likeService: LikeService,
    location: Location,
    activatedRoute: ActivatedRoute,
  ) {
    const sanitizedQueryParameters = MovieDateService.sanitizedQueryParameters(activatedRoute.snapshot);
    const initialSearchParameters: MovieDateSearchParameters = {
      ...MovieDateService.defaultSearchParameters,
      ...withoutUndefined(sanitizedQueryParameters),
    };

    super(
      `${environment.apiGatewayUrl}v1/me-kino/date`,
      initialSearchParameters,
      'me-kino',
      http,
      logger,
      likeService,
      location,
    );
  }

  private static sanitizedQueryParameters(
    routeSnapshot: ActivatedRouteSnapshot
  ): Omit<MovieDateSearchParameters, 'page' | 'perPage'> {
    const queryParameters = { ...routeSnapshot.queryParams };
    Object.keys(queryParameters).forEach(key => queryParameters[key] = decodeURIComponent(queryParameters[key]));

    return {
      ids: queryParameters.ids,
      query: queryParameters.query,
      startDate: queryParameters.startDate,
      endDate: queryParameters.endDate,
      genres: queryParameters.genres,
      cinemas: queryParameters.cinemas,
      ov: queryParameters.ov === 'true' ? true : undefined,
    };
  }

  resetFilters() {
    const nextParams: MovieDateSearchParameters = { ...MovieDateService.defaultSearchParameters };
    const [query, ids] = [this.searchParameters$.value.query, this.searchParameters$.value.ids];
    if (query) {
      nextParams.query = query;
    }
    if (ids) {
      nextParams.ids = ids;
    }
    this.searchParameters$.next(nextParams);
  }


  get numberOfActiveFilters$(): Observable<number> {
    return this.searchParameters$.pipe(
      map(searchParameters => {
        const params: Partial<MovieDateSearchParameters> = { ...searchParameters };
        delete params.page; // don't count the `page` param
        delete params.perPage; // don't count the `perPage` param
        delete params.query; // don't count the query
        delete params.ids; // don't count IDs

        return Object.values(params)
          .filter((value) => {
            switch (typeof value) {
              case 'undefined':
                return false;
              case 'boolean':
                return value; // don't count false booleans
              case 'string':
                return value.trim() !== ''; // don't count empty strings
              default:
                return true;
            }
          })
          .length;
      }),
    );
  }

  protected id(item: MovieDate): string {
    return item.id;
  }

  protected items(resources: ResourceCollection<MovieDateResource>): MovieDate[] {
    return resources.data.map(resource => new MovieDate(resource));
  }

}
