import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalDatePipe } from '../local-date/local-date.pipe';

@Pipe({
  name: 'dates'
})
export class DatesPipe implements PipeTransform {
  datePipe: LocalDatePipe;

  constructor(@Inject(LOCALE_ID) localeId) {
    this.datePipe = new LocalDatePipe(localeId);
  }

  transform(value: Date[], format = 'dd.MM.YYYY HH:mm', timezone?: string, locale?: string): string[] {
    return value
      .map(date => this.datePipe.transform(date, format, timezone, locale))
      .filter((v): v is string => !!v);
  }
}

