import { Image } from '../../image/image';
import { MeLocalCategory } from '../category/me-local-category';
import { MeLocalObjectResource } from './me-local-object-resource';
import { Location } from '../../location';
import { Contact } from '../../contact';
import { Router } from '@angular/router';
import { Routable } from '../../routable';

export class MeLocalObject implements Routable {

  id: number;
  title: string;
  description: null | string;
  categories: MeLocalCategory[];
  location: Location;
  contact: Contact;
  thumbnail: Image;
  images: Image[];
  openingHours: null | string;

  constructor(resource: MeLocalObjectResource['data'], categoryRootID?: number) {
    this.id = resource.id;
    this.title = resource.title;
    this.description = resource.description;
    this.categories = resource.categories.map(category => new MeLocalCategory(category, categoryRootID));
    this.location = {
      name: resource.title,
      ...resource.address,
      street: `${resource.address.street} ${resource.address.houseNumber ?? ''}`.trim() || null,
    };
    this.contact = resource.contact;
    const fallbackImage = {
      src: this.categories.find(category => category.thumbnailSrc)?.thumbnailSrc ?? 'assets/dummies/neutral.svg',
      author: null,
    };
    this.thumbnail = resource.thumbnail || fallbackImage;
    this.images = resource.images.length > 0 ? resource.images : [this.thumbnail];
    this.openingHours = resource.openingHours;
  }

  routerLink(router: null | Router = null) {
    if (!router) {
      return ['/me-local', this.id];
    }

    const path = router.url.includes('likes') ? '..' : '.';
    return [path, this.id];
  }

}
