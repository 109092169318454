import {Component, Input} from '@angular/core';
import { expand } from '../expander/expander.component';

@Component({
  selector: 'mn-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  animations: [expand],
})
export class DescriptionComponent{
  @Input() chips: string[] = [];
  @Input() description: string | null;

  isDescriptionExpanded = false;

  get isDescriptionExpandable(): boolean {
    return (this.description?.length || 0) > 800;
  }

  constructor() { }

}
