import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Like, LikeKey } from '../entities/like';
import { dayjs } from '../support/dayjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends Dexie {

  private static readonly dbName = 'me-freizeit';

  likes: Dexie.Table<Like, LikeKey>;

  constructor() {
    super(DatabaseService.dbName);

    this.version(1).stores({
      likes: '[type+id],type,id,expiresAt',
    });

    this.version(2).stores({
      likes: '[type+id],type,id,expiresAt',
    }).upgrade((transaction) => transaction
      .table('likes')
      .toCollection()
      .modify((like) => {
        if (like.type !== 'msu-event') { return; }
        like.id = `${like.id}D${dayjs(like.expiresAt).format('YYYY-MM-DD')}`;
      })
    );
  }

}
