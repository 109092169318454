export type EventFeatureKey = 'online' | 'eintritt-frei' | 'benefiz' | 'fuer-kinder' | 'anmeldung' | string;

export const isEventFeatureKey = (key: string): key is EventFeatureKey => Object.keys(EventFeature.features)
  .includes(key);

export class EventFeature {
  static get all() {
    return Object.keys(EventFeature.features).map(key => new EventFeature(key));
  }

  static readonly features: { [key: string]: string } = {
    'eintritt-frei': 'Eintritt frei',
    online: 'Online',
    benefiz: 'Benefiz',
    'fuer-kinder': 'Für Kinder',
    anmeldung: 'Mit Anmeldung'
  };

  readonly key: string;
  readonly title: string;

  constructor(key: EventFeatureKey) {
    this.key = key;
    this.title = EventFeature.features[key]
      ?? key
        .split('-')
        .map(substring => substring[0].toLocaleUpperCase() + substring.substr(1))
        .join(' ');
  }
}
