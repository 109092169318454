import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalDatePipe } from '../local-date/local-date.pipe';

@Pipe({
  name: 'nullableTime'
})
export class NullableTimePipe implements PipeTransform {

  datePipe: LocalDatePipe;

  constructor(@Inject(LOCALE_ID) localeID: string) {
    this.datePipe = new LocalDatePipe(localeID);
  }

  transform(
    value: Date | string | number, format?: string, timezone?: string, locale?: string
  ): string | null {
    if (this.datePipe.transform(value, 'H:mm', timezone, locale) !== '0:00') {
      return this.datePipe.transform(value, format, timezone, locale);
    }

    return null;
  }

}
