import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { dayjs } from '../../../support/dayjs';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe extends DatePipe implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string
  ): string | null {
    const utcOffset = dayjs(value).tz('Europe/Berlin').format('ZZ');
    return super.transform(value, format, timezone || utcOffset, locale);
  }

}
