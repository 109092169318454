import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mn-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {

  @Input() icon: string;

  constructor() { }

  ngOnInit() {}

}
