import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, animate, state, style, transition } from '@angular/animations';

export const expand = trigger('expand', [
  state('false', style({ height: '0px' })),
  state('true', style({ height: '*' })),
  state('some', style({ height: '200px' })),
  state('all', style({ height: '*' })),
  transition('false <=> true, some <=> all', animate('300ms ease-in-out')),
]);

@Component({
  selector: 'mn-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
})
export class ExpanderComponent {

  @Input() subject = 'anzeigen';
  @Input() isExpandable = true;
  @Input() isExpanded = false;
  @Output() isExpandedChange = new EventEmitter<boolean>();

}
