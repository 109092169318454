import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LikeService, Model } from '../../../services/like.service';
import { Like } from '../../../entities/like';
import { Image } from '../../../entities/image/image';

@Component({
  selector: 'mn-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnChanges {

  @Input() images: Image[];
  @Input() like: undefined | null | Like;
  @Input() model: undefined | null | Model;
  @Input() shareURL: undefined | null | string;

  sliderOptions: HTMLIonSlidesElement['options'];

  constructor(
    private likeService: LikeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.images) { return; }
    this.sliderOptions = this.images.length <= 1
      ? {}
      : {
        loop: true,
        pagination: {
          el: '.hero__pager',
          type: 'fraction'
        },
      };
  }

  async toggleLike() {
    if(this.model) {
      this.like = await this.likeService.toggle(this.model);
    }
  }

}
